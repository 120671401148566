import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
// import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useForm, Controller } from "react-hook-form";
import API from "../common/api";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      {new Date().getFullYear()}
      {"."} {"All Rights Reserved by "}
      <Link color="primary" to="https://expressoneltd.com/">
        Express One ltd.
      </Link>{" "}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(5),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorDiv: {
    textAlign: "right",
  },
  errorMessage: {
    color: "red",
    marginLeft: "8px",
  },
  registerText: {
    color: "#2E3192",
    fontFamily: "cursive",
    fontSize: "16px",
  },
  loginText: {
    color: "#2E3192",
    fontFamily: "cursive",
    fontSize: "16px",
  },
}));

export default function Registration() {
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data) => {
    setIsLoading(true);
    // console.log(JSON.stringify(data));

    const res = await API.post("/auth/register", data);

    if (res.success === 1) {
      setIsLoading(false);
      history.push("/login");
    } else {
      setIsLoading(false);
      toast.error(res.message);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />

      <div className={classes.paper}>
        <img
          src="https://expressoneltd.com/images/Logo.png"
          alt="ExpressOneLtdImg"
          width="400"
          height="100"
        />

        <form
          className={classes.form}
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="app_user_first_name"
            label="First Name"
            name="app_user_first_name"
            autoFocus
            inputRef={register({
              required: "This Field is Required",
            })}
          />
          {errors.app_user_first_name && (
            <Grid item xs={12} className={classes.errorDiv}>
              <span className={classes.errorMessage}>
                * {errors.app_user_first_name?.message}
              </span>
            </Grid>
          )}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="app_user_last_name"
            label="Last Name"
            name="app_user_last_name"
            autoFocus
            inputRef={register({
              required: "This Field is Required",
            })}
          />
          {errors.app_user_last_name && (
            <Grid item xs={12} className={classes.errorDiv}>
              <span className={classes.errorMessage}>
                * {errors.app_user_last_name?.message}
              </span>
            </Grid>
          )}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="app_user_email"
            label="Email"
            name="app_user_email"
            autoFocus
            inputRef={register({
              required: "This Field is Required",
            })}
          />
          {errors.app_user_email && (
            <Grid item xs={12} className={classes.errorDiv}>
              <span className={classes.errorMessage}>
                * {errors.app_user_email?.message}
              </span>
            </Grid>
          )}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="app_user_mobile"
            label="Mobile"
            name="app_user_mobile"
            autoFocus
            inputRef={register({
              required: "This Field is Required",
            })}
          />
          {errors.app_user_mobile && (
            <Grid item xs={12} className={classes.errorDiv}>
              <span className={classes.errorMessage}>
                * {errors.app_user_mobile?.message}
              </span>
            </Grid>
          )}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="app_user_password"
            label="Password"
            type="password"
            id="app_user_password"
            inputRef={register({
              required: "This Field is Required",
            })}
          />
          {errors.app_user_password && (
            <Grid item xs={12} className={classes.errorDiv}>
              <span className={classes.errorMessage}>
                * {errors.app_user_password?.message}
              </span>
            </Grid>
          )}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="confirmPassword"
            label="Confirm Password"
            type="password"
            id="confirmPassword"
            inputRef={register({
              required: "This Field is Required",
            })}
          />
          {errors.confirmPassword && (
            <Grid item xs={12} className={classes.errorDiv}>
              <span className={classes.errorMessage}>
                * {errors.confirmPassword?.message}
              </span>
            </Grid>
          )}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={isLoading}
          >
            Register
          </Button>
          <span className={classes.loginText}>
            Already have an account? Please <Link to="/login">Login</Link>
          </span>
          <br />
          <span className={classes.loginText}>
            Admin Panel <Link to="/admin">Login</Link>
          </span>
        </form>
      </div>
      <Box mt={4}>
        <Copyright />
      </Box>
    </Container>
  );
}
