import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import API from "./../../common/api";
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      {new Date().getFullYear()}
      {"."} {"All Rights Reserved by "}
      <Link color="primary" to="https://expressoneltd.com/">
        Express One ltd.
      </Link>{" "}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(5),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorDiv: {
    textAlign: "right",
  },
  errorMessage: {
    color: "red",
    marginLeft: "8px",
  },
  headingTitle: {
    color: "#2E3192",
    fontFamily: "cursive",
    marginBottom: theme.spacing(5),
  },
}));

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const validateMobile = (mobile) => {
  return String(mobile)
    .toLowerCase()
    .match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/);
};

const saveItemInLocalStorage = (res) => {
  localStorage.setItem("accessToken", res.accessToken);
  localStorage.setItem("admin_username", res.data.admin_username);
  localStorage.setItem("admin_role", res.data.admin_role);
  
};
export default function AdminLogin() {
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  const history = useHistory();

  const onSubmit = async (data) => {
    // console.log(JSON.stringify(data));

    // console.log(result);
    const res = await API.post("/auth/admin-login", data);

    if (res.success === 1) {
      // console.log("accessToken: " + res.accessToken);

      saveItemInLocalStorage(res);

      history.push("/adminApproval");
    } else {
      toast.error(res.message);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />

      <div className={classes.paper}>
        <h1 className={classes.headingTitle}>Admin Panel Login</h1>
        <img
          src="https://expressoneltd.com/images/Logo.png"
          alt="ExpressOneLtdImg"
          width="400"
          height="100"
        />
        <form
          className={classes.form}
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="User Name"
            name="admin_username"
            autoFocus
            inputRef={register({
              required: "This Field is Required",
            })}
          />
          {errors.username && (
            <Grid item xs={12} className={classes.errorDiv}>
              <span className={classes.errorMessage}>
                * {errors.username?.message}
              </span>
            </Grid>
          )}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="admin_password"
            label="Password"
            type="password"
            id="password"
            inputRef={register({
              required: "This Field is Required",
            })}
          />
          {errors.password && (
            <Grid item xs={12} className={classes.errorDiv}>
              <span className={classes.errorMessage}>
                * {errors.password?.message}
              </span>
            </Grid>
          )}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Log In
          </Button>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
