import { makeStyles } from "@material-ui/core/styles";
import AutorenewOutlinedIcon from "@material-ui/icons/AutorenewOutlined";
import MaterialTable from "material-table";
import AppBar from "@material-ui/core/AppBar";
import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import API from "../../common/api";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  changeStatus: {
    color: "#2a5298",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    flexGrow: 1,
  },
  logo: {
    marginLeft: theme.spacing(1),
    flexGrow: 1,
  },
}));

function AdminApproval() {
  const classes = useStyles();
  const history = useHistory();

  const [columns, setColumns] = useState([
    {
      title: "SL",
      field: "sl_no",
      cellStyle: {
        textAlign: "left",
        paddingLeft: "32px",
      },
      headerStyle: {
        paddingLeft: "32px",
      },
    },
    {
      title: "Name",
      field: "name",
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      title: "Email",
      field: "app_user_email",
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      title: "Mobile NO.",
      field: "app_user_mobile",
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      title: "Password",
      field: "app_user_password",
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      title: "Created At",
      field: "app_user_created_at",
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      title: "Last Update",
      field: "app_user_updated_at",
      cellStyle: {
        textAlign: "left",
      },
    },
    {
      title: "Status",
      field: "app_user_status",
      render: (rowData) =>
        rowData.app_user_status === "Active" ? (
          <p style={{ fontWeight: "bold", color: "green" }}>
            {rowData.app_user_status}
          </p>
        ) : (
          <p style={{ fontWeight: "bold", color: "red" }}>
            {rowData.app_user_status}
          </p>
        ),
      cellStyle: {
        textAlign: "left",
        paddingRight: "32px",
      },
      headerStyle: {
        paddingRight: "32px",
      },
    },
  ]);
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isDisableBtn, setIsDisableBtn] = useState(false);
  const isAuthenticated = localStorage.getItem("accessToken");
  useEffect(() => {
    const admin_role = localStorage.getItem("admin_role");
    if(admin_role !== "ADMIN"){
      history.push("/admin");
    }
    async function fetchData() {
      const res = await API.get("/auth/get-all-users");
      setData(res.data);
    }
    fetchData();
  }, []);

  const updateUserStatus = async (rowData) => {
    const displayName = rowData.app_user_email
      ? rowData.app_user_email
      : rowData.app_user_mobile;
    const status = rowData.app_user_status === "Active" ? "Inactive" : "Active";
    alert(`${displayName} Status Changed to ${status}`);

    const data = {
      id_pk: rowData.id_pk,
      app_user_status: status,
    };
    setIsDisableBtn(true);
    const res = await API.post("/auth/update-user-status", data);

    if (res.success === 1) {
      setIsDisableBtn(false);
      history.go(0);
    } else {
      toast.error(res.message);
    }
  };

  const logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("admin_username");
    localStorage.removeItem("admin_role");
    history.push("/");
  };
  return (
    <React.Fragment>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="static" style={{ background: "rgb(252 252 252)" }}>
          <Toolbar>
            <Box className={classes.logo}>
              <Link to="/">
                <img
                  src="https://expressoneltd.com/images/Logo.png"
                  alt="ExpressOneLtdImg"
                  width="200"
                  height="50"
                />
              </Link>
            </Box>
            {isAuthenticated ? (
              <Button variant="contained" color="primary" onClick={logout}>
                LogOut
              </Button>
            ) : null}
          </Toolbar>
        </AppBar>
        <main className={classes.content}>
          <h1>User List</h1>
          <MaterialTable
            title=""
            columns={columns}
            data={data}
            onRowClick={(evt, selectedRow) =>
              setSelectedRow(selectedRow.tableData.id)
            }
            options={{
              exportButton: true,
              exportAllData: true,
              search: true,
              pageSize: 10,
              pageSizeOptions: [50, 100, 200],
              actionsColumnIndex: -1,
              headerStyle: {
                textAlign: "left",
                paddingRight: "32px",
              },

              actionsCellStyle: {
                paddingRight: "32px",
                justifyContent: "center",
              },
            }}
            actions={[
              {
                icon: () => (
                  <AutorenewOutlinedIcon className={classes.changeStatus} />
                ),
                tooltip: "Change Status",
                onClick: (event, rowData) => {
                  // console.log("ID " + rowData.id_pk);
                  if (!isDisableBtn) {
                    updateUserStatus(rowData);
                  }
                },
              },
            ]}
          />
        </main>
      </div>
    </React.Fragment>
  );
}

export default withRouter(AdminApproval);
