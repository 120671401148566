import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory, withRouter } from "react-router-dom";
import API from "../common/api";
import SubmenuButtons from "../components/SubmenuButtons";
import { employeeButtonLabel } from "../constants/buttonLabelName";
import { employeeRoutes } from "../constants/subMenuRoutes";
import SearchComponent from "./employee/SearchComponent";
import SaveIcon from "@material-ui/icons/Save";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MaterialTable from "material-table";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4, 2, 2, 2),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  gridPadding: {
    padding: theme.spacing(0, 3, 0, 0),
  },
  textFieldInput: {
    padding: theme.spacing(0.5),

  },
  inputRow: {
    padding: theme.spacing(1, 3, 0, 3),
  },
  button: {
    margin: theme.spacing(2, 0, 2, 2),
  },
  labelText: {
    textAlign: "right",
    fontWeight: "bold",
    color: "black",
    padding: theme.spacing(0, 2, 0, 0),
  },
  errorDiv: {
    textAlign: "right",
  },
  errorMessage: {
    color: "red",
    marginLeft: "8px",
  },
  cardValidityPeriodWidth: {
    width: "140px",
  },
  toText: {
    margin: theme.spacing(2, 0, 0, 0),
    fontWeight: "bold",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
    padding: theme.spacing(0),
    margin: theme.spacing(0),
  },
}));
function Settings() {
  const classes = useStyles();
  const { register, errors, handleSubmit } = useForm();
  const { register: register2, errors: errors2, handleSubmit: handleSubmit2 } = useForm();
  const { register: register3, errors: errors3, handleSubmit: handleSubmit3 } = useForm();


  const [isDisableBtn, setIsDisableBtn] = useState(false);
  const [dataFormSend, setDataFormSend] = useState({});
  const history = useHistory();


  const [columns, setColumns] = useState([
    {
      title: "Sl",
      field: "id",
      cellStyle: {
        textAlign: "left",
        paddingLeft: "32px",
        marginBottom: "32px",
      },
      headerStyle: {
        paddingLeft: "32px",
      },
    },
    {
      title: "File Name",
      field: "file_name",
      cellStyle: {
        textAlign: "left",
        paddingRight: "32px",
      },
      headerStyle: {
        paddingRight: "32px",
      },
    },
  ]);
  const [data, setData] = useState([
    { id: "01", file_name: "Profile Picture.jpg" },
    { id: "02", file_name: "TestFile.doc" },
  ]);
  const [selectedRow, setSelectedRow] = useState(null);
  const matches = useMediaQuery("(min-width:600px)");


  const [fileDB, setFileDB] = useState("");

  const onSubmitUnitName = async (data) => {
    setIsLoading(true);
    console.log(JSON.stringify(data));

    const res = await API.post("/settings/unitName", data);

    if (res.success === 1) {
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const onSubmitDesignationName = async (data) => {
    setIsLoading(true);
    console.log(JSON.stringify(data));

    const res = await API.post("/settings/designation", data);

    if (res.success === 1) {
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const onSubmitHelpCenterInfo = async (data) => {
    const res = await API.post("/helpCenter", data);

    if (res.success === 1) {
      history.push("/helpCenterView");
    }
  };

  const [permission, setPermission] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [helpCenterDeskNumber, setHelpCenterDeskNumber] = useState("");
  const [unitNames, setUnitNames] = useState([]);
  const [designations, setDesignations] = useState([]);

  useEffect(() => {
    setIsLoading(true);

    const app_user_permission = localStorage.getItem("app_user_permission");
    if (app_user_permission) {
      setPermission(app_user_permission);
    }

    async function fetchData() {
      const res = await API.get("/helpCenter/getHelpCenterDeskNumber");
      if (res.data) {
        setHelpCenterDeskNumber(res.data.help_center_desk_number);
      }

      const resUnitNames = await API.get(`/settings/unitName`);
      console.log("RES: " + JSON.stringify(resUnitNames));
      if (resUnitNames.data) {
        setUnitNames(resUnitNames.data);
      }

      const resDesignations = await API.get(`/settings/designation`);
      console.log("RES: " + JSON.stringify(resDesignations));
      if (resDesignations.data) {
        setDesignations(resDesignations.data);
        setIsLoading(false);
      }
      setIsLoading(false);
    }
    fetchData();
  }, [permission]);

  return (
    <main className={classes.content}>
      <SubmenuButtons
        routes={employeeRoutes}
        buttonLabelName={employeeButtonLabel}
      />

      {!isLoading && permission.includes("C") && (
        <div>
          {/* SEARCH EMPLOYEES FILES START */}
          <h1>Settings</h1>
          {/* ADD UNIT NAME & DESIGNATION START */}
          <Paper className={classes.paperTable}>
            <List className={classes.root}>
              {/* search input field */}

              <Grid container>
                <Grid item xs={12}>
                  <form onSubmit={handleSubmit(onSubmitUnitName)} autoComplete="off">
                    {/*Unit Name*/}
                    <ListItem className={classes.inputRow}>
                      <InputLabel
                        htmlFor="unit_name"
                        className={classes.labelText} >
                        Unit Name:
                      </InputLabel>
                      <TextField
                        id="unit_name"
                        variant="outlined"
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register({
                          required: "This Field is Required",
                        })}
                        name="unit_name"
                      />
                      {errors.unit_name && (
                        <Grid item className={classes.errorDiv}>
                          <span className={classes.errorMessage}>
                            * {errors.unit_name?.message}
                          </span>
                        </Grid>
                      )}
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.button}
                        onClick={handleSubmit(onSubmitUnitName)}
                        disabled={isLoading}
                      >
                        Add
                      </Button>
                    </ListItem>
                  </form>

                  <form onSubmit={handleSubmit2(onSubmitDesignationName)} autoComplete="off">
                    {/*Designation*/}
                    <ListItem className={classes.inputRow}>
                      <InputLabel
                        htmlFor="designation"
                        className={classes.labelText} >
                        Designation:
                      </InputLabel>
                      <TextField
                        id="designation"
                        variant="outlined"
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                        }}
                        inputRef={register2({
                          required: "This Field is Required",
                        })}
                        name="designation" />
                      {errors2.designation && (
                        <Grid item className={classes.errorDiv}>
                          <span className={classes.errorMessage}>
                            * {errors2.designation?.message}
                          </span>
                        </Grid>
                      )}
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.button}
                        onClick={handleSubmit2(onSubmitDesignationName)}
                        disabled={isLoading}
                      >
                        Add
                      </Button>

                    </ListItem>
                  </form>
                </Grid>
              </Grid>
            </List>
          </Paper>
          {/* ADD UNIT NAME & DESIGNATION END */}

          {/* ADD HELP CENTER INFO START */}
          <div>
            <h1>Add Help Center Info</h1>
            {isLoading ? (
              <div>Loading...</div>
            ) : (
              <Paper className={classes.paper}>
                <List className={classes.root}>
                  {/* autoComplete="off" */}
                  <form onSubmit={handleSubmit3(onSubmitHelpCenterInfo)} autoComplete="off">
                    <Grid container>
                      <Grid item xs={12} sm={6}>
                        {/* EOL help desk number */}
                        <ListItem className={classes.inputRow}>
                          <Grid container alignItems="center">
                            <Grid item xs={6}>
                              <InputLabel
                                htmlFor="help_center_desk_number"
                                className={classes.labelText}
                              >
                                Eol Help Desk Number:
                              </InputLabel>
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                id="help_center_desk_number"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                  classes: {
                                    input: classes.textFieldInput,
                                  },
                                }}
                                inputRef={register3}
                                name="help_center_desk_number"
                                defaultValue={helpCenterDeskNumber}
                              />
                            </Grid>
                            {errors3.help_center_desk_number && (
                              <Grid item xs={12} className={classes.errorDiv}>
                                <span className={classes.errorMessage}>
                                  * {errors3.help_center_desk_number?.message}
                                </span>
                              </Grid>
                            )}
                          </Grid>
                        </ListItem>

                        {/* Designation*/}
                        <ListItem className={classes.inputRow}>
                          <Grid container alignItems="center">
                            <Grid item xs={6}>
                              <InputLabel
                                htmlFor="help_center_designation"
                                className={classes.labelText}
                              >
                                Designation:
                              </InputLabel>
                            </Grid>
                            <Grid item xs={6}>
                              <select
                                className={classes.dropDown}
                                name="help_center_designation"
                                defaultValue=""
                                ref={register3({
                                  required: "This Field is Required",
                                })}
                              >
                                <option value="" disabled>
                                  Select Designation
                                </option>
                                {designations && designations.map((d, index) => {
                                  return <option value={d.designation} key={index}>{d.designation}</option>

                                })}
                              </select>
                            </Grid>
                            {errors3.help_center_designation && (
                              <Grid item xs={12} className={classes.errorDiv}>
                                <span className={classes.errorMessage}>
                                  * {errors3.help_center_designation?.message}
                                </span>
                              </Grid>
                            )}
                          </Grid>
                        </ListItem>

                        {/* Designation short form*/}
                        <ListItem className={classes.inputRow}>
                          <Grid container alignItems="center">
                            <Grid item xs={6}>
                              <InputLabel
                                htmlFor="help_center_designation_short_form"
                                className={classes.labelText}
                              >
                                Designation Short Form:
                              </InputLabel>
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                id="help_center_designation_short_form"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                  classes: {
                                    input: classes.textFieldInput,
                                  },
                                }}
                                inputRef={register3({
                                  required: "This Field is Required",
                                })}
                                name="help_center_designation_short_form"
                              />
                            </Grid>
                            {errors3.help_center_designation_short_form && (
                              <Grid item xs={12} className={classes.errorDiv}>
                                <span className={classes.errorMessage}>
                                  *{" "}
                                  {errors3.help_center_designation_short_form?.message}
                                </span>
                              </Grid>
                            )}
                          </Grid>
                        </ListItem>

                        {/* Unit name short form */}
                        <ListItem className={classes.inputRow}>
                          <Grid container alignItems="center">
                            <Grid item xs={6}>
                              <InputLabel
                                htmlFor="help_center_unit_name_short_form"
                                className={classes.labelText}
                              >
                                Unit Name Short Form:
                              </InputLabel>
                            </Grid>
                            <Grid item xs={6}>
                              <select
                                className={classes.dropDown}
                                name="help_center_unit_name_short_form"
                                defaultValue=""
                                ref={register3({
                                  required: "This Field is Required",
                                })}
                              >
                                <option value="" disabled>
                                  Select Unit Name
                                </option>
                                {unitNames && unitNames.map((d, index) => {
                                  return <option value={d.unit_name} key={index}>{d.unit_name}</option>
                                })}
                              </select>
                            </Grid>
                            {errors3.help_center_unit_name_short_form && (
                              <Grid item xs={12} className={classes.errorDiv}>
                                <span className={classes.errorMessage}>
                                  * {errors3.help_center_unit_name_short_form?.message}
                                </span>
                              </Grid>
                            )}
                          </Grid>
                        </ListItem>

                        {/* Unit name details*/}
                        <ListItem className={classes.inputRow}>
                          <Grid container alignItems="center">
                            <Grid item xs={6}>
                              <InputLabel
                                htmlFor="help_center_unit_name_details"
                                className={classes.labelText}
                              >
                                Unit Name Details:
                              </InputLabel>
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                id="help_center_unit_name_details"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                  classes: {
                                    input: classes.textFieldInput,
                                  },
                                }}
                                inputRef={register3({
                                  required: "This Field is Required",
                                })}
                                name="help_center_unit_name_details"
                              />
                            </Grid>
                            {errors3.help_center_unit_name_details && (
                              <Grid item xs={12} className={classes.errorDiv}>
                                <span className={classes.errorMessage}>
                                  * {errors3.help_center_unit_name_details?.message}
                                </span>
                              </Grid>
                            )}
                          </Grid>
                        </ListItem>

                        {/* Description */}
                        <ListItem className={classes.inputRow}>
                          <Grid container alignItems="center">
                            <Grid item xs={6}>
                              <InputLabel
                                htmlFor="help_center_description"
                                className={classes.labelText}
                              >
                                Description:
                              </InputLabel>
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                id="help_center_description"
                                variant="outlined"
                                fullWidth
                                InputProps={{
                                  classes: {
                                    input: classes.textFieldInput,
                                  },
                                }}
                                inputRef={register3({
                                  required: "This Field is Required",
                                })}
                                name="help_center_description"
                              />
                            </Grid>
                            {errors3.help_center_description && (
                              <Grid item xs={12} className={classes.errorDiv}>
                                <span className={classes.errorMessage}>
                                  * {errors3.help_center_description?.message}
                                </span>
                              </Grid>
                            )}
                          </Grid>
                        </ListItem>

                        {/* Save Button */}
                        <ListItem className={classes.inputRow}>
                          <Grid container alignItems="center">
                            <Grid item xs={12}>
                              <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="small"
                                className={classes.button}
                                startIcon={<SaveIcon />}
                                onClick={handleSubmit3(onSubmitHelpCenterInfo)}
                              >
                                Save
                              </Button>
                            </Grid>
                          </Grid>
                        </ListItem>
                      </Grid>
                    </Grid>
                  </form>
                </List>
              </Paper>
            )}
          </div>
          {/* ADD HELP CENTER INFO END */}

        </div>
      )}


    </main>
  );
}

export default withRouter(Settings);
