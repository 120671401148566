import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import axios from "axios";
import "jspdf-autotable";
import MaterialTable from "material-table";
import { useHistory } from "react-router-dom";
import API from "../../common/api";
import SubmenuButtons from "../../components/SubmenuButtons";
import { employeeButtonLabel } from "../../constants/buttonLabelName";
import { AXIOS_BASE_URL } from "../../constants/constants";
import { employeeRoutes } from "../../constants/subMenuRoutes";
import jsPDF from "jspdf";
import "jspdf-autotable";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4, 2, 2, 2),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  textFieldInput: {
    padding: theme.spacing(1),
  },
  inputRow: {
    padding: theme.spacing(1, 3, 0, 3),
  },
  button: {
    margin: theme.spacing(2, 0, 2, 0),
  },
  labelText: {
    textAlign: "right",
    fontWeight: "bold",
    color: "black",
    padding: theme.spacing(0, 2, 0, 0),
  },
  errorDiv: {
    textAlign: "right",
  },
  errorMessage: {
    color: "red",
    marginLeft: "8px",
  },
  customTable: {
    border: "1px solid black",
    borderCollapse: "collapse",
    width: "100%",
    margin: theme.spacing(0, 0, 4, 0),
  },
  customTableTdLeft: {
    border: "1px solid black",
    borderCollapse: "collapse",
    width: "450px",
    textAlign: "left",
    fontWeight: "bold",
    padding: theme.spacing(0, 1, 0, 1),
  },
  customTableTdLeftSum: {
    border: "1px solid black",
    borderCollapse: "collapse",
    width: "450px",
    textAlign: "left",
    fontWeight: "bold",
    fontSize: "16px",
    padding: theme.spacing(0, 1, 0, 1),
  },
  customTableTdRightSum: {
    border: "1px solid black",
    borderCollapse: "collapse",
    width: "450px",
    textAlign: "left",
    fontWeight: "bold",
    fontSize: "16px",
    padding: theme.spacing(0, 1, 0, 1),
  },
  customTableTdMiddle: {
    border: "1px solid black",
    borderCollapse: "collapse",
    width: "100px",
    textAlign: "left",
    fontWeight: "bold",
    padding: theme.spacing(0, 1, 0, 1),
  },
  customTableTdRight: {
    border: "1px solid black",
    borderCollapse: "collapse",
    // width: "200px",
    textAlign: "left",
    fontWeight: "bold",
    padding: theme.spacing(0, 1, 0, 1),
  },
  companyTitle: {
    textAlign: "center",
    fontSize: "24px",
    fontFamily: "Arial",
    padding: theme.spacing(0),
    margin: theme.spacing(0),
  },
  paper: {
    margin: theme.spacing(4, 0, 0, 0),
    padding: theme.spacing(4),
  },
  subTitle: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "14px",
    padding: theme.spacing(0),
    margin: theme.spacing(0),
  },
  headerEmployee: {
    position: "relative",
    margin: theme.spacing(0, 0, 10, 0),
  },
  headerGuarantor: { position: "relative", margin: theme.spacing(0, 0, 20, 0) },
  profileImg: {
    position: "absolute",
    top: "8px",
    right: "16px",
    height: "120px",
    weight: "120px",
    border: "1px solid #0000000f",
  },
  signatureImg: {
    height: "40px",
    weight: "40px",
  },
  tableTitle: {
    // textDecoration: "underline",
    fontWeight: "bold",
    fontSize: "14px",
    fontFamily: "Arial",
    padding: theme.spacing(0),
    margin: theme.spacing(0, 0, 1, 0),
  },
  empViewPage: {
    margin: theme.spacing(4),
  },
}));

function HelpCenterView() {
  const classes = useStyles();
  const history = useHistory();

  // btn download start---------------------------------
  const [anchorEl, setAnchorEl] = useState(null);

  const handleDownloadClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDownloadClose = () => {
    setAnchorEl(null);
  };

  // btn download end--------------------------------

  // console.log("EmployeeView ID-------: " + location.state.id);

  const [data, setData] = useState([]);
  const [joiningSalaryData, setJoiningSalaryData] = useState([]);
  const [salaryData, setSalaryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [help_center_desk_number, setHelp_center_desk_number] = useState("");

  const [permission, setPermission] = useState("");

  useEffect(() => {
    async function fetchData() {
      //   setIsLoading(true);

      const res = await API.get("/helpCenter");
      if (res.data.length > 0) {
        setData(res.data);
        if (res.data[res.data.length - 1].help_center_desk_number) {
          setHelp_center_desk_number(
            res.data[res.data.length - 1].help_center_desk_number
          );
        }
      }
    }
    fetchData();

    const app_user_permission = localStorage.getItem("app_user_permission");
    if (app_user_permission) {
      setPermission(app_user_permission);
    }
  }, [permission]);

  const getEmployeeListPDF = async (columns, data) => {
    const doc = new jsPDF();

    const columnTitles = columns.map((columnDef) => {
      return columnDef.title;
    });

    const pdfData = data.map((rowData) =>
      columns.map((columnDef) => rowData[columnDef.field])
    );

    doc.autoTable({
      head: [columnTitles],
      body: pdfData,
    });

    doc.save(`EmployeeList.pdf`);
  };

  const [columns, setColumns] = useState([
    {
      title: "SL",
      field: "sl_no",
      cellStyle: {
        textAlign: "left",
        paddingLeft: "32px",
      },
      headerStyle: {
        paddingLeft: "32px",
        fontWeight: "bold",
      },
    },
    {
      title: "Designation",
      field: "help_center_designation",
      cellStyle: {
        textAlign: "left",
      },
      headerStyle: {
        fontWeight: "bold",
      },
    },
    {
      title: "Designation Short Form",
      field: "help_center_designation_short_form",
      cellStyle: {
        textAlign: "left",
      },
      headerStyle: {
        fontWeight: "bold",
      },
    },
    {
      title: "Unit Name Short Form",
      field: "help_center_unit_name_short_form",
      cellStyle: {
        textAlign: "left",
      },
      headerStyle: {
        fontWeight: "bold",
      },
    },
    {
      title: "Unit Name Details",
      field: "help_center_unit_name_details",
      cellStyle: {
        textAlign: "left",
      },
      headerStyle: {
        fontWeight: "bold",
      },
    },
    {
      title: "Description",
      field: "help_center_description",
      cellStyle: {
        textAlign: "left",
        paddingRight: "32px",
      },
      headerStyle: {
        paddingRight: "32px",
        fontWeight: "bold",
      },
    },
  ]);

  const [selectedRow, setSelectedRow] = useState(null);
  const [isDisableBtn, setIsDisableBtn] = useState(false);

  const deleteRecordById = async (id) => {
    const res = await API.delete(`/helpCenter/${id}`);
    if (res.success === 1) {
      history.go(0);
    }
    setIsDisableBtn(true);
  };
  return (
    <main className={classes.content}>
      <SubmenuButtons
        routes={employeeRoutes}
        buttonLabelName={employeeButtonLabel}
      />
      <h1>Eol Help Desk Number: {help_center_desk_number}</h1>
      <MaterialTable
        title=""
        columns={columns}
        data={data}
        onRowClick={(evt, selectedRow) =>
          setSelectedRow(selectedRow.tableData.id)
        }
        options={{
          exportButton: false,
          exportAllData: false,
          exportPdf: (columns, data) => {
            // getEmployeeListPDF(columns, data);
          },
          search: false,
          pageSize: 20,
          pageSizeOptions: [50, 100, 200],
          actionsColumnIndex: -1,
          headerStyle: {
            textAlign: "left",
            paddingRight: "32px",

            fontWeight: "bold",
          },

          actionsCellStyle: {
            paddingRight: "32px",
            justifyContent: "center",
          },
        }}
        actions={ permission.includes("D") && [
          {
            icon: () => <DeleteOutlineOutlinedIcon />,
            tooltip: "Delete Record",
            onClick: (event, rowData) => {
              if (!isDisableBtn) {
                deleteRecordById(rowData.id_pk);
              }
            },
          },
        ]}
      />
    </main>
  );
}

export default HelpCenterView;
